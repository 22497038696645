import React from "react";
import { connect } from "react-redux";
import { Drawer as AntdDrawer } from "antd";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import isEqual from "fast-deep-equal";
import i18next from "i18next";
import {
  whiteSmoke,
  darkGray,
  lightMidGray,
  white,
  platinum,
  regentGray,
  error,
} from "utils/constants/colors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";

import ModalService from "services/modal";
import ConfirmModal from "components/common/ConfirmationModal";
import Button, { TextButton } from "components/ui/Button";
import HelpIcon from "components/common/ClusterProfiles/HelpIcon";

const discardChangesConfirmation = new ModalService();

const StyledDrawer = styled(AntdDrawer)`
  .ant-drawer-content {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    background: ${whiteSmoke};
    ${(props) =>
      props.theme === "light" &&
      css`
        background: ${white};
      `}

    max-width: ${({ width }) => width};
    width: 100%;

    .ant-drawer-header {
      background: ${white};
    }
  }

  .ant-drawer-body {
    overflow-y: auto;
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: start;
    border-top: 1px solid ${lightMidGray};
    padding: 12px 24px;
  }

  .ant-drawer-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${darkGray};
    padding: 0 16px;
  }

  .ant-drawer-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid ${lightMidGray};
  }

  .ant-drawer-close {
    padding: 0;
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${darkGray};
    font-size: 14px;
    font-weight: bold;
  }

  .ant-drawer-footer,
  .ant-drawer-header {
    background: ${whiteSmoke};
    flex-basis: 64px;
    display: flex;
    align-items: center;
    ${(props) =>
      props.theme === "light" &&
      css`
        background: ${white};
      `}
  }

  ${(props) =>
    props.hasSidebar &&
    css`
      .ant-drawer-body {
        flex: 1;
        padding: 0;
        height: 100%;
      }
    `}
`;

const ExtraWrap = styled.div`
  display: flex;
  align-items: center;
`;

const HelpComponentWrap = styled.span`
  margin-right: 10px;
`;

const StyledDrawerActions = styled.div`
  padding-top: 12px;
  border-top: 1px solid ${platinum};
`;

const RowWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${regentGray};
  margin-left: 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  svg {
    font-size: 16px;
    color: #6ddcb4;
    margin-right: 8px;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${error};
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  > svg {
    font-size: 16px;
    color: ${error};
    margin-right: 8px;
  }
`;

const DeleteButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px 12px;

  > span {
    color: ${regentGray};
    margin-bottom: 11px;
    font-weight: normal;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0.5px;
  }

  > button {
    width: 100%;
  }
`;

const StyledTextButton = styled(TextButton)`
  display: flex;
  align-items: center;
  align-self: flex-start;
  color: ${error};
  &:hover,
  &:focus,
  &:active {
    color: ${error};
  }
  svg {
    margin-right: 10px;
    font-size: 15px;
  }
`;

export function DeleteButton({ message, buttonLabel, ...rest }) {
  return (
    <DeleteButtonWrap>
      {message && <span>{message}</span>}
      <StyledTextButton data-qa="delete-action" danger {...rest}>
        <FontAwesomeIcon icon={faTrashAlt} color={error} />
        <span>{buttonLabel}</span>
      </StyledTextButton>
    </DeleteButtonWrap>
  );
}

function DrawerFooter({
  onSave,
  onDiscard,
  formData,
  isInitialForm,
  disabled,
  successMessage = i18next.t("Changes saved successfully"),
}) {
  const { t } = useTranslation();
  const { isLoading, submitting, errors, submitted } = formData;

  function renderErrorMessage() {
    if (!errors?.length) {
      return null;
    }
    return (
      <ErrorMessage>
        <FontAwesomeIcon icon={faExclamationCircle} />
        {t("Oops! There are some issues in the form, please correct them.")}
      </ErrorMessage>
    );
  }

  function renderSuccessMessage() {
    if (!submitted) {
      return null;
    }
    return (
      <SuccessMessage>
        <FontAwesomeIcon icon={faCheckCircle} />
        {successMessage}
      </SuccessMessage>
    );
  }

  function renderDiscardButton() {
    if (isInitialForm || submitted || isLoading) {
      return null;
    }
    return (
      <TextButton
        data-qa="action-discard"
        onClick={onDiscard}
        disabled={submitting}
      >
        {t("Discard")}
      </TextButton>
    );
  }

  return (
    <StyledDrawerActions>
      {renderErrorMessage()}
      <RowWrap>
        <Button
          key="submit"
          data-qa="action-save"
          onClick={onSave}
          disabled={
            isInitialForm ||
            submitted ||
            isLoading ||
            errors?.length ||
            disabled
          }
          loading={submitting}
        >
          {t("Save Changes")}
        </Button>

        {renderDiscardButton()}
        {renderSuccessMessage()}
      </RowWrap>
      <ConfirmModal service={discardChangesConfirmation} type="warning">
        <ConfirmModal.Question>
          {t(
            "Your current progress will be lost. Are you sure you want to continue?"
          )}
        </ConfirmModal.Question>
      </ConfirmModal>
    </StyledDrawerActions>
  );
}

export const ConnectedDrawerFooter = connect(
  (state, ownProps) => {
    const currentForm = state.forms?.[ownProps.module];

    return {
      formData: currentForm || {},
      isInitialForm:
        currentForm !== undefined &&
        isEqual(currentForm.data, currentForm.initialData),
    };
  },
  (dispatch, ownProps) => {
    const { module, formActions, modalService, onResolve } = ownProps;

    async function onSave() {
      await dispatch(formActions.submit({ module }));
      if (modalService && modalService instanceof ModalService) {
        modalService.resolve();
      }
      if (onResolve && typeof onResolve === "function") {
        onResolve();
      }
    }

    function onDiscard() {
      return (dispatch, getState) => {
        const initialData =
          getState().forms[ownProps.module]?.initialData || {};
        discardChangesConfirmation.open().then(() => {
          dispatch(
            formActions.batchChange({
              module,
              updates: {
                ...initialData,
              },
            })
          );
          dispatch(formActions.clearErrors({ module }));
        });
      };
    }

    return {
      onSave,
      onDiscard: () => dispatch(onDiscard()),
    };
  }
)(DrawerFooter);

function DefaultDrawerButtons({
  ConfirmButton,
  confirmLabel,
  onConfirm,
  disabledConfirm,
  loading,
  onCancel,
  errors,
}) {
  const { t } = useTranslation();

  function renderErrorMessage() {
    if (!errors?.length) {
      return null;
    }
    return (
      <ErrorMessage>
        <FontAwesomeIcon icon={faExclamationCircle} />
        {t("Oops! There are some issues in the form, please correct them.")}
      </ErrorMessage>
    );
  }

  return (
    <div>
      {renderErrorMessage()}
      <ConfirmButton
        key="submit"
        data-qa="drawer-confirm"
        onClick={onConfirm}
        disabled={disabledConfirm}
        loading={loading}
      >
        {confirmLabel || t("Confirm")}
      </ConfirmButton>
      <TextButton
        key="cancel"
        data-qa="drawer-cancel"
        onClick={onCancel}
        disabled={loading}
        secondary
      >
        {t("Cancel")}
      </TextButton>
    </div>
  );
}

const Drawer = ({
  width,
  visible,
  disabledConfirm,
  title,
  onConfirm,
  confirmLabel,
  onCancel,
  children,
  name,
  maskClosable = false,
  loading,
  ModalButtons = DefaultDrawerButtons,
  ConfirmButton = Button,
  hasSidebar,
  errors,
  submitting,
  help,
  extra,
  ...rest
}) => {
  const buttonProps = {
    confirmLabel,
    onConfirm,
    disabledConfirm: disabledConfirm || errors?.length,
    loading: loading || submitting,
    onCancel,
    ConfirmButton,
    errors,
  };

  const helpComponent = help ? (
    <>
      <HelpIcon title={help.title} docLocation={help.docLocation} />
    </>
  ) : null;

  return (
    <StyledDrawer
      width={width}
      visible={visible}
      title={title}
      name={name}
      onClose={onCancel}
      onOk={onConfirm}
      centered
      hasSidebar={hasSidebar}
      destroyOnClose={true}
      maskClosable={maskClosable}
      maskStyle={{
        backgroundColor: "rgba(43, 50, 60, 0.8)",
      }}
      footer={<ModalButtons {...buttonProps} />}
      extra={
        extra ? (
          <ExtraWrap>
            <HelpComponentWrap>{helpComponent}</HelpComponentWrap>
            {extra}
          </ExtraWrap>
        ) : (
          helpComponent
        )
      }
      {...rest}
    >
      {children}
    </StyledDrawer>
  );
};

StyledDrawer.defaultProps = {
  width: "480px",
};

export default connect(
  (state, ownProps) => ({
    visible: ownProps?.service?.isOpened() || ownProps.visible,
    errors: state.forms?.[ownProps.module]?.errors,
    submitting: state.forms?.[ownProps.module]?.submitting,
  }),
  (_, ownProps) => {
    return {
      onCancel: () => {
        const closeAction =
          ownProps.onClose || ownProps.service.reject || ownProps.service.close;
        closeAction();
      },
      onConfirm: ownProps.onConfirm || (() => ownProps.service.resolve()),
    };
  }
)(Drawer);
